import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, IconButton, TextField, Typography } from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../components/payment/checkoutForm';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { mainColor } from 'components/theme';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import CloseIcon from '@mui/icons-material/Close';
import * as API from 'api/baseAPI';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const appearance = {
  labels: 'floating',
  rules: {
    '.Input': {
      color: '#000000',
    },
    '.Input:focus': {
      borderColor: mainColor,
    },
    '.Input::placeholder': {
      color: '#99999',
    },
  },
};

const formatTimeLeft = (timeLeft) => {
  if (!timeLeft) return '00:00';
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const PaymentForm = (props) => {
  const { registeredSuccessResponse, record, onCancel } = props;
  const [stripeOptions] = useState({
    // passing the client secret obtained in step 3
    clientSecret: registeredSuccessResponse.paymentIntent.clientSecret,
    // Fully customizable with appearance API.
    appearance: appearance,
  });

  return (
    <Box sx={{ flex: 1 }}>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CheckoutForm
          registeredSuccessResponse={registeredSuccessResponse}
          record={record}
          onCancel={onCancel}
        />
      </Elements>
    </Box>
  );
};

const FreePaymentWrapper = (props) => {
  const { onCancel, onConfirm } = props;
  const { t } = useTranslation();
  const [deadline, setDeadline] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);

  useEffect(() => {
    setDeadline(Date.now() + 15 * 60 * 1000); // 15 minutes from now
  }, []);

  useEffect(() => {
    if (!deadline) return;
    let timer = null;

    const checkTime = () => {
      const remaining = deadline - Date.now();
      if (remaining <= 0) {
        setTimeLeft(0);
        clearInterval(timer);
        timer = null;
        return;
      }
      setTimeLeft(Math.floor(remaining / 1000));
    };

    timer = setInterval(checkTime, 1000);
    checkTime();
    return () => clearInterval(timer);
  }, [deadline, t]);

  const handleConfirm = () => {
    if (deadline && Date.now() > deadline) {
      alert(t('register.error-timeout'));
      onCancel(false);
      return;
    }

    onConfirm();
  };

  return (
    <Box sx={{ flex: 1 }}>
      <div
        style={{
          display: 'flex',
          gap: '16px',
          justifyContent: 'space-between',
        }}
      >
        <Button
          variant="outlined"
          fullWidth
          onClick={() => onCancel(true)}
          sx={{
            height: '40px',
            maxWidth: '200px',
          }}
        >
          {t('register.label-back')}
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleConfirm}
          sx={{
            height: '40px',
            maxWidth: '200px',
          }}
        >
          {t('app.confirm')}
        </Button>
      </div>
      <div style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>
        {t('register.time-left', { time: formatTimeLeft(timeLeft) })}
      </div>
    </Box>
  );
};

const RsvpFormPayment = (props) => {
  const { formData, record, registeredSuccessResponse, setActiveStep, ...rest } = props;
  const { i18n, t } = useTranslation();
  const [discountCode, setDiscountCode] = useState('');
  const [successResponse, setSuccessResponse] = useState(registeredSuccessResponse);
  const [discountApplied, setDiscountApplied] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // const formData = useWatch({});
  // console.log('defaultData', defaultData);
  // console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;

  const handleApplyDiscountCode = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const filterEmptyValue = Object.fromEntries(
      Object.entries(formData).filter(([_, v]) => typeof v === 'boolean' || (v && v.length > 0))
    );
    const emailTypeField = rsvpData.fields.find((it) => it.type === 'email');
    const email = filterEmptyValue[emailTypeField.key];

    const _discountCode = discountCode.trim();

    API.postUserRegisterPayment(record.id, email, filterEmptyValue, _discountCode)
      .then((resp) => {
        setSuccessResponse(resp.data);
        setDiscountApplied(true);
        setSubmitting(false);
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 &&
          (err?.response?.data?.code === 'INVALID_DISCOUNT_CODE' ||
            err?.response?.data?.code === 'DISCOUNT_CODE_QUOTA_EXCEEDED')
        ) {
          if (err?.response?.data?.code === 'INVALID_DISCOUNT_CODE') {
            alert(t('register.error-invalid-discount-code'));
          } else if (err?.response?.data?.code === 'DISCOUNT_CODE_QUOTA_EXCEEDED') {
            alert(t('register.error-discount-code-quota-exceeded'));
          }
          setSubmitting(false);
          return;
        }
        alert(t('register.error-fail-to-apply-discount-code'));
        setSubmitting(false);
      });
  };

  const handleCancelDiscountCode = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    const filterEmptyValue = Object.fromEntries(
      Object.entries(formData).filter(([_, v]) => typeof v === 'boolean' || (v && v.length > 0))
    );
    const emailTypeField = rsvpData.fields.find((it) => it.type === 'email');
    const email = filterEmptyValue[emailTypeField.key];

    API.cancelRegistrationPayment(successResponse.id)
      .then(() => {})
      .catch((err) => {
        console.log('err', err);
      });

    API.postUserRegisterPayment(record.id, email, filterEmptyValue)
      .then((resp) => {
        setSuccessResponse(resp.data);
        setDiscountApplied(false);
        setDiscountCode('');
        setSubmitting(false);
      })
      .catch((err) => {
        alert(t('register.error-fail-to-apply-discount-code'));
        setSubmitting(false);
      });
  };

  const handleConfirm = () => {
    if (submitting) {
      return;
    }
    setSubmitting(true);
    API.proceedRegistrationPayment(successResponse.id)
      .then((resp) => {
        setActiveStep(3);
        setSubmitting(false);
      })
      .catch((err) => {
        console.log('err', err);
        alert(t('register.error-fail-to-proceed-payment'));
        setSubmitting(false);
      });
  };

  const handleCancel = (callApi) => {
    if (callApi) {
      API.cancelRegistrationPayment(successResponse.id)
        .then(() => {})
        .catch((err) => {
          console.log('err', err);
        });
    }

    setActiveStep(2);
  };

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
      Preview
    </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            p: 2,
            // textAlign: 'center',
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {getTimeRange(record, i18n.language)}
          </Typography>
          <Typography variant="h3">
            {getMultiLocaleContent(record.title, i18n.language, true)}
          </Typography>

          <Divider sx={{ my: 2 }} />

          {rsvpData?.payment?.supportDiscountCode && (
            <Box sx={{ mt: 2 }}>
              <TextField
                size="small"
                label={t('register.label-discount-code')}
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
                sx={{ width: '200px' }}
                disabled={discountApplied}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={discountApplied ? handleCancelDiscountCode : handleApplyDiscountCode}
                      disabled={!discountApplied && !discountCode.trim()}
                      sx={{
                        '&:hover': {
                          backgroundColor: 'transparent',
                        },
                        padding: '4px',
                        marginRight: '-12px',
                      }}
                    >
                      {discountApplied ? (
                        <CloseIcon sx={{ color: 'red' }} />
                      ) : (
                        <DoubleArrowIcon color={!discountCode.trim() ? 'disabled' : 'primary'} />
                      )}
                    </IconButton>
                  ),
                }}
              />
            </Box>
          )}

          {successResponse.paymentIntent.originalAmountInCents !==
          successResponse.paymentIntent.amountInCents ? (
            <Box sx={{ mt: 1 }}>
              <Typography variant="h6" sx={{ mt: 1 }}>
                {t('register.discounted')}: HKD{' '}
                {(successResponse.paymentIntent.amountInCents / 100).toFixed(2)}{' '}
                <span
                  style={{
                    textDecoration: 'line-through',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    color: 'gray',
                  }}
                >
                  HKD {(successResponse.paymentIntent.originalAmountInCents / 100).toFixed(2)}
                </span>
              </Typography>
            </Box>
          ) : (
            <Typography variant="h6" sx={{ mt: 2 }}>
              {t('register.price')}: HKD{' '}
              {(successResponse.paymentIntent.originalAmountInCents / 100).toFixed(2)}
            </Typography>
          )}

          <Divider sx={{ my: 2 }} />

          <Box sx={{ my: 2, display: 'flex', justifyContent: 'center' }}>
            {successResponse.paymentIntent.amountInCents === 0 ? (
              <FreePaymentWrapper
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                key={successResponse.id}
              />
            ) : (
              <PaymentForm
                registeredSuccessResponse={successResponse}
                record={record}
                onCancel={handleCancel}
                key={successResponse.id}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RsvpFormPayment;

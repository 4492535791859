import React, { useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useWatch } from 'react-hook-form';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';
import { EVENT_TYPE } from 'constant';

const RsvpFormFinish = (props) => {
  const { record, registeredSuccessResponse, ...rest } = props;
  const { i18n, t } = useTranslation();

  const formData = useWatch({});
  // console.log('defaultData', defaultData);
  // console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;
  const hideQRCode = rsvpData.hideQRCode !== undefined ? rsvpData.hideQRCode : false;

  const eventType = useMemo(() => {
    return formData?.eventType || EVENT_TYPE.PHYSICAL;
  }, [formData]);

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
        Preview
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#fff',
              p: 2,
              textAlign: 'center',
            }}
          >
            <Typography variant="h4" sx={{ mt: 3 }}>
              {t('register.register-finish-title')}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {getMultiLocaleContent(record?.title, i18n.language, true)}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: 'gray' }}>
              {getTimeRange(record, i18n.language)}
            </Typography>

            {eventType === EVENT_TYPE.PHYSICAL &&
              registeredSuccessResponse.qrcodeUrl &&
              !hideQRCode && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant="subtitle1" sx={{ mt: 4 }}>
                    {t('register.register-finish-desc-1')}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mt: 1 }}>
                    {t('register.register-finish-desc-2')}
                  </Typography>
                  <Box
                    id="QRCode"
                    component="img"
                    sx={{
                      mt: 2,
                      maxWidth: '250px',
                      mx: 'auto',
                      border: '1px solid black',
                      borderRadius: 4,
                    }}
                    src={registeredSuccessResponse.qrcodeUrl}
                    alt="QR Code"
                  />
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    {registeredSuccessResponse.passcode}
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      '& .MuiButton-startIcon': { marginRight: '0px' },
                      textTransform: 'none',
                      width: ['100%', 'auto'],
                    }}
                    onClick={() => {
                      fetch(registeredSuccessResponse.qrcodeUrl)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const currentTimestamp = Date.now();
                          saveAs(
                            blob,
                            `checkin_qrcode_${record.title?.en}_${currentTimestamp}.png`
                          );
                        })
                        .catch((e) => {
                          alert(t('register.error-fail-download-image'));
                        });
                    }}
                  >
                    <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-download-image')}</Box>
                  </Button>
                  <Typography variant="subtitle1" sx={{ color: 'gray', mt: 4 }}>{`${t(
                    'register.reference-no'
                  )}: ${registeredSuccessResponse.id}`}</Typography>
                </Box>
              )}
            {(eventType === EVENT_TYPE.VIRTUAL || hideQRCode) && (
              <Box>
                <Typography variant="subtitle1" sx={{ mt: 4 }}>
                  {t('register.register-finish-general-desc-1')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('register.register-finish-general-desc-2')}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: 'gray', mt: 4 }}>{`${t(
                  'register.reference-no'
                )}: ${registeredSuccessResponse.id}`}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RsvpFormFinish;

const translation = {
  general: {
    minute_one: '{{ count }} minute',
    minute_other: '{{ count }} minutes',
    hour_one: '{{ count }} hour',
    hour_other: '{{ count }} hours',
  },
  app: {
    exit: 'Exit',
    close: 'Close',
    footer: 'Powered by AsiaSat Media Technology Limited',
    'login-btn': 'Log In',
    'logout-btn': 'Log Out',
    confirm: 'Confirm',
  },
  login: {
    title: 'User Login',
    'email-label': 'Enter your email',
    'not-registered-remark':
      "If you're not registered yet, please enter your email address and click 'Confirm' below.",
    'otp-guide-title': 'OTP Sent',
    'otp-label': 'Enter your OTP',
    'otp-remark-1': 'We have sent an OTP to your email address.',
    'otp-remark-2': 'Please complete the login process within 15 minutes.',
    'next-step': 'Next Step',
    'email-error': 'Incorrect email. Please try logging in again.',
    'unknown-error': 'An error occurred. Please try logging in again.',
    'network-error':
      'Network connectivity issue detected. Please try a different network if the problem persists.',
    'otp-error': 'Incorrect OTP. Please try logging in again.',
    'otp-fatal-error':
      'OTP timeout or incorrect OTP entered more than 3 times. Please try logging in again.',
  },
  home: {
    title: 'Home',
  },
  event: {
    info: 'Information',
    'q-and-a': 'Q&A',
    question: {
      placeholder: 'There will be a Q&A session. Please submit your question here.',
      submit: 'Submit',
      'succeed-message': 'Thank you for your question.',
      'failed-message': 'Submission failed. Please try again later.',
      'exceeds-char-limit-message': 'You can enter up to 200 characters.',
    },
    chatroom: 'Chatroom',
    polling: 'Polling',
    schedule: 'Schedule',
    si: {
      english: 'eng',
      cantonese: 'can',
      putonghua: 'pth',
    },
    playback: {
      'error-title': 'Video Playback Error',
      'error-message':
        'Your network may be unstable, or you might not have authorization to access this video.',
    },
    'just-started': 'Just started',
    'started-ago': 'Started {{age}} ago',
    'concurrent-error-message': 'Your account is logged in on another device.',
    'connection-error-message': 'Network is unstable.',
    'require-login-message': 'Please log in if you have access to this event.',
    'session-kicked-error-message':
      'Your account is logged in on another device. Click the button below to view on this device.',
    'view-here': 'View Here',
    unauthorized: 'You are not authorized to view this content.',
  },
  passcode: {
    label: 'Please enter your passcode:',
    enter: 'Enter',
    remark: 'Contact the event organizer if you have not received your passcode.',
    error: 'Incorrect passcode.',
    'select-placeholder': 'Please select',
  },
  register: {
    'date-and-time': 'Date & Time',
    venue: 'Venue',
    about: 'About this event',
    'step-1-label': 'Event Information',
    'step-2-label': 'Fill RSVP Form',
    'step-3-label': 'Confirm',
    'step-4-label': 'Finish',
    'register-finish-title': 'Registration Successful!',
    'register-finish-desc-1': 'Show the QR code below during check-in.',
    'register-finish-desc-2': 'You may take a screenshot or download the image.',
    'register-finish-virtual-desc-1':
      'Check your email for the virtual event link. We look forward to your participation!',
    'register-finish-general-desc-1':
      'Please check your email for further details about the event.',
    'register-finish-general-desc-2': 'We look forward to your participation!',
    'confirm-your-registration': 'Confirm Your Registration',
    payment: 'Payment',
    'amount-to-pay': 'Amount to Pay',
    original: 'Original',
    discounted: 'Special Offer',
    'label-download-image': 'Download Image',
    'label-join-now': 'Register Now',
    'label-back': 'Back',
    'label-submit': 'Submit',
    'label-confirm': 'Confirm',
    'label-region': 'Region',
    'label-required-field': '* Required field',
    'label-register-form': 'Registration Form',
    'label-discount-code': 'Discount Code',
    'error-fail-download-image': 'Failed to download image.',
    'error-email-registered': 'This email address is already registered.',
    'error-fail-register': 'Failed to submit form. Please try again later.',
    'error-fail-email-not-found': 'Failed to submit form. Email field not found.',
    'error-invalid-field': 'Invalid {{field}}.',
    'error-invalid-discount-code': 'Invalid discount code.',
    'error-fail-to-apply-discount-code': 'Failed to apply discount code.',
    'error-discount-code-quota-exceeded': 'Discount code quota exceeded.',
    'error-fail-to-confirm': 'Failed to confirm.',
    apply: 'Apply',
    pay: 'Pay',
    price: 'Price',
    'error-timeout': 'Your session has expired. Please confirm your details and submit again.',
    'time-left': 'Time left: {{time}}',
    'reference-no': 'Reference Number',
    'payment-failed': 'Payment Failed, please try again.',
    'payment-processing': 'Payment is being processed, please wait...',
    'registration-completed': 'Registration closed. Thank you for your participation!',
  },
};

export default translation;

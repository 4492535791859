import React, { Fragment } from 'react';
import { Box, Typography, Button } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Link } from '@mui/material';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';
import { useTranslation } from 'react-i18next';

const EventPreview = (props) => {
  const { record, onJoinClick, ...rest } = props;
  const { i18n, t } = useTranslation();

  //   console.log('defaultData', defaultData);
  //   console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;
  const websiteLink = rsvpData?.website;
  const email = rsvpData?.email;
  const contactNo = rsvpData?.contactNo;
  const title = getMultiLocaleContent(record?.title, i18n.language, true);
  const status = record?.status || 'ongoing';

  const addressText = getMultiLocaleContent(rsvpData?.address?.text, i18n.language, true);
  const addressLat = rsvpData?.address?.lat;
  const addressLon = rsvpData?.address?.lon;
  const addressZoom = parseFloat(rsvpData?.address?.z ?? 10).toFixed(0);

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: '80px',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
        Preview
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              whiteSpace: 'pre-line',
              wordBreak: 'break-word',
              typography: { xs: 'h4', md: 'h3' },
            }}
          >
            {title}
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 500, mt: 4 }}>
            {t('register.date-and-time')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              display: 'flex',
              alignItems: 'flex-start',
              my: 1,
              color: '#333333',
            }}
          >
            <CalendarMonthIcon sx={{ fontSize: '20px', mr: 1 }} />
            {getTimeRange(record, i18n.language)}
          </Typography>

          {addressText && (
            <Fragment>
              <Typography variant="h5" sx={{ fontWeight: 500, mt: 4 }}>
                {t('register.venue')}
              </Typography>
              {addressLat && addressLon && (
                <Box
                  id="previewInput_address_embedUrl"
                  sx={{
                    mt: 2,
                    width: ['100%', '500px'],
                    height: '300px',
                    iframe: {
                      border: 0,
                      width: '100%',
                      height: '100%',
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://maps.google.com/maps?q=${addressLat},${addressLon}&hl=${i18n.language}&z=${addressZoom}&output=embed"/>`,
                  }}
                />
              )}
              <Box
                id="previewInput_address"
                sx={{ display: 'inline-flex', alignItems: 'top', my: 1, color: '#333333' }}
              >
                <PlaceIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Typography
                  variant="subtitle2"
                  sx={{ m: 0, whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                >
                  {addressText}
                </Typography>
              </Box>
            </Fragment>
          )}

          {getMultiLocaleContent(record?.detailDescription, i18n.language, true) && (
            <Fragment>
              <Typography variant="h5" color="title" sx={{ fontWeight: 500, mt: 4 }}>
                {t('register.about')}
              </Typography>
              <Typography
                sx={{ img: { maxWidth: '100%' } }}
                variant="subtitle1"
                dangerouslySetInnerHTML={{
                  __html: getMultiLocaleContent(record?.detailDescription, i18n.language, true),
                }}
              />
            </Fragment>
          )}

          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: 'left',
              justifyContent: 'space-between',
            }}
          >
            {websiteLink && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1, color: '#333333' }}>
                <LinkIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Link
                  href={websiteLink}
                  target="_blank"
                  color="inherit"
                  underline="hover"
                  sx={{ display: 'inline-flex', alignItems: 'center' }}
                >
                  <Typography variant="subtitle2">{websiteLink}</Typography>
                </Link>
              </Box>
            )}

            {contactNo && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1, color: '#333333' }}>
                <PhoneIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Typography variant="subtitle2">{contactNo}</Typography>
              </Box>
            )}

            {email && (
              <Box sx={{ display: 'inline-flex', alignItems: 'center', mt: 1, color: '#333333' }}>
                <EmailIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Link href={`mailto:${email}`} color="inherit" underline="hover">
                  <Typography variant="subtitle2">{email}</Typography>
                </Link>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '16px',
              backgroundColor: 'background.paper',
              borderTop: 1,
              borderColor: 'divider',
              zIndex: 1000,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {status === 'done' ? (
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                {t('register.registration-completed')}
              </Typography>
            ) : (
              <Button
                variant="outlined"
                onClick={() => onJoinClick()}
                sx={{
                  '& .MuiButton-startIcon': { marginRight: '0px' },
                  textTransform: 'none',
                  width: ['100%', 'auto'],
                }}
              >
                <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-join-now')}</Box>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default EventPreview;

const translation = {
  general: {
    minute_one: '{{ count }}分鐘',
    minute_other: '{{ count }}分鐘',
    hour_one: '{{ count }}小時',
    hour_other: '{{ count }}小時',
  },
  app: {
    exit: '退出',
    close: '關閉',
    footer: '由 AsiaSat Media Technology Limited 提供技術支持',
    'login-btn': '登入',
    'logout-btn': '登出',
    confirm: '確認',
  },
  login: {
    title: '用戶登入',
    'email-label': '輸入您的電郵',
    'not-registered-remark': '如果您尚未註冊，請輸入您的電郵地址並點擊下方"確認"按鈕。',
    'otp-guide-title': '驗證碼已發送',
    'otp-label': '輸入您的驗證碼',
    'otp-remark-1': '我們已將驗證碼發送到您的電郵地址。',
    'otp-remark-2': '請在15分鐘內完成登入。',
    'next-step': '下一步',
    'email-error': '電郵不正確，請重新登入。',
    'unknown-error': '發生錯誤，請重新登入。',
    'network-error': '檢測到網絡連接問題。如果問題持續，請嘗試使用其他網絡。',
    'otp-error': '驗證碼不正確，請重新輸入。',
    'otp-fatal-error': '驗證碼超時或錯誤次數超過3次，請重試。',
  },
  home: {
    title: '首頁',
  },
  event: {
    info: '詳情',
    'q-and-a': '問答',
    question: {
      placeholder: '設有問答環節，如您有任何提問，請在這裡輸入。',
      submit: '提交',
      'succeed-message': '感謝您的提問。',
      'failed-message': '提交失敗，請稍後再試。',
      'exceeds-char-limit-message': '最多可以輸入100個中文字（200字符）。',
    },
    chatroom: '聊天室',
    polling: '投票',
    schedule: '時間表',
    si: {
      english: '英語',
      cantonese: '粵語',
      putonghua: '普通話',
    },
    playback: {
      'error-title': '視頻播放錯誤',
      'error-message': '您的網絡可能不穩定，或者您沒有權限觀看此視頻。',
    },
    'just-started': '剛剛開始',
    'started-ago': '{{age}}前開始',
    'concurrent-error-message': '您的賬戶已在其他設備上登入。',
    'connection-error-message': '網絡不穩定。',
    'require-login-message': '如果您有權限觀看此活動，請登入。',
    'session-kicked-error-message': '您的賬戶已在其他設備上登入。點擊下方按鈕在此設備上觀看。',
    'view-here': '在此觀看',
    unauthorized: '您無權查看此內容。',
  },
  passcode: {
    label: '請輸入您的密碼：',
    enter: '確定',
    remark: '如果您未收到密碼，請聯絡活動主辦方。',
    error: '密碼不正確。',
    'select-placeholder': '請選擇',
  },
  register: {
    'step-1-label': '活動信息',
    'step-2-label': '填寫 RSVP 表格',
    'step-3-label': '確認',
    'step-4-label': '完成',
    'register-finish-title': '註冊成功！',
    'register-finish-desc-1': '簽到時請出示以下二維碼。',
    'register-finish-desc-2': '您可以截圖或下載圖片。',
    'register-finish-virtual-desc-1': '請檢查您的電子郵件以獲取虛擬活動鏈接。期待您的參與！',
    'confirm-your-registration': '確認您的註冊',
    payment: '付款',
    'amount-to-pay': '應付金額',
    original: '原價',
    discounted: '優惠價',
    'label-download-image': '下載圖片',
    'label-join-now': '立即註冊',
    'label-back': '返回',
    'label-submit': '提交',
    'label-confirm': '確認',
    'label-region': '地區',
    'label-required-field': '* 表示必填欄位',
    'label-register-form': '註冊表格',
    'label-discount-code': '優惠碼',
    'error-fail-download-image': '無法下載圖片。',
    'error-email-registered': '此電子郵件地址已經註冊。',
    'error-fail-register': '提交表單失敗。請稍後再試。',
    'error-fail-email-not-found': '提交表單失敗。找不到電子郵件欄位。',
    'error-invalid-field': '{{field}} 不正確。',
    'error-invalid-discount-code': '優惠碼不正確。',
    'error-fail-to-apply-discount-code': '應用優惠碼失敗。',
    'error-discount-code-quota-exceeded': '優惠碼配額已用完。',
    'error-fail-to-confirm': '確認失敗。',
    apply: '應用',
    pay: '付款',
    price: '價格',
    'error-timeout': '您的會話已過期，請確認您的信息並重新提交。',
    'time-left': '剩餘時間：{{time}}',
    'reference-no': '參考編號',
    'payment-failed': '付款失敗，請重試。',
    'payment-processing': '付款處理中，請稍候...',
    'registration-completed': '註冊已結束。感謝您的參與！',
  },
};

export default translation;

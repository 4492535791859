import React, { useState, useEffect } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';

const formatTimeLeft = (timeLeft) => {
  if (!timeLeft) return '00:00';
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const CheckoutForm = (props) => {
  const { registeredSuccessResponse, record, onCancel } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [ready, setReady] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [timeLeft, setTimeLeft] = useState(null);
  const [amountText] = useState(
    `HKD ${(registeredSuccessResponse.paymentIntent.amountInCents / 100).toFixed(2)}`
  );

  useEffect(() => {
    setDeadline(Date.now() + 15 * 60 * 1000); // 15 minutes from now
  }, []);

  React.useEffect(() => {
    if (!deadline) return;
    console.log('triggered');
    let timer = null;

    const checkTime = () => {
      const remaining = deadline - Date.now();
      if (remaining <= 0) {
        setTimeLeft(0);
        clearInterval(timer);
        timer = null;
        return;
      }
      setTimeLeft(Math.floor(remaining / 1000));
    };

    timer = setInterval(checkTime, 1000);
    checkTime(); // Initial check
    return () => clearInterval(timer);
  }, [deadline, t]);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    // Check if payment time has expired
    if (deadline && Date.now() > deadline) {
      alert(t('register.error-timeout'));
      onCancel(false);
      return;
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/register/${record.id}/return?response=${Buffer.from(
          JSON.stringify(registeredSuccessResponse)
        ).toString('base64')}`,
      },
    });

    if (result.error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)

      alert(`${t('register.payment-failed')}: ${result.error.message}`);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement onReady={() => setReady(true)} disableLink={true} style={{ width: '100%' }} />
      {ready && (
        <div
          style={{
            display: 'flex',
            gap: '16px',
            justifyContent: 'space-between',
            marginTop: '40px',
          }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={() => onCancel(true)}
            sx={{
              height: '40px',
              maxWidth: '200px',
            }}
          >
            {t('register.label-back')}
          </Button>
          <Button
            variant="contained"
            fullWidth
            disabled={!stripe}
            type="submit"
            sx={{
              height: '40px',
              maxWidth: '200px',
              '&.Mui-disabled': {
                color: '#999999',
                backgroundColor: '#ECECEC',
              },
            }}
          >
            {t('register.pay')} {amountText}
          </Button>
        </div>
      )}
      <div style={{ textAlign: 'center', marginTop: '20px', color: '#666' }}>
        {t('register.time-left', { time: formatTimeLeft(timeLeft) })}
      </div>
    </form>
  );
};

export default CheckoutForm;

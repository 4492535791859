import { Fragment, useEffect, useRef, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { createTheme, ThemeProvider } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as API from 'api/baseAPI';
import RsvpFormFinish from './RsvpFormFinish';
import { getMultiLocaleContent } from 'utils/helper';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Box } from '@mui/material';
import { mainColor } from 'components/theme';
import { setGlobalLoading } from 'redux/appSlice';
import eventDuckIcon from '../images/logo.svg';
import FormLocaleSwitcher from 'locales/_FormLocaleSwitcher';
import { Buffer } from 'buffer';
import { EVENT_TYPE } from 'constant';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Result = (props) => {
  const { record, registeredSuccessResponse } = props;
  const stripe = useStripe();
  const [status, setStatus] = useState('');
  const { t } = useTranslation();
  const [inputEventType, setInputEventType] = useState(EVENT_TYPE.PHYSICAL);
  const methods = useForm({
    defaultValues: {
      eventType: EVENT_TYPE.PHYSICAL,
    },
  });

  useEffect(() => {
    methods.setValue('eventType', inputEventType);
  }, [inputEventType, methods]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    // Retrieve the "payment_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            //setMessage('Success! Payment received.')
            API.getRegistration(record.id, registeredSuccessResponse.passcode)
              .then((response) => {
                if (response?.data?.metadata?.eventType) {
                  setInputEventType(response.data.metadata.eventType);
                }
                setStatus('succeeded');
              })
              .catch((err) => {
                console.error(err);
              });

            break;

          case 'processing':
            // setMessage("Payment processing. We'll update you when payment is received.")
            setStatus('processing');
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            // setMessage('Payment failed. Please try another payment method.')
            setStatus('failed');
            break;

          default:
            // setMessage('Something went wrong.')
            setStatus('failed');
            break;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [stripe, record.id, registeredSuccessResponse.passcode]);

  return (
    <Fragment>
      {status === 'succeeded' && (
        <FormProvider {...methods}>
          <Fragment>
            <RsvpFormFinish record={record} registeredSuccessResponse={registeredSuccessResponse} />
          </Fragment>
        </FormProvider>
      )}
      {status === 'failed' && <Box>{t('register.payment-failed')}</Box>}
      {status === 'processing' && <Box>{t('register.payment-processing')}</Box>}
    </Fragment>
  );
};

const ResultWrapper = (props) => {
  const { record, registeredSuccessResponse } = props;
  return (
    <Elements stripe={stripePromise}>
      <Result record={record} registeredSuccessResponse={registeredSuccessResponse} />
    </Elements>
  );
};

export default function PaymentReturn(props) {
  const params = useParams();
  const [notFound, setNotFound] = useState(false);
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);

  const advance = searchParams.get('advance') === 'true';
  const rsvpRef = useRef({});
  const eventRef = useRef({});
  const themeRef = useRef(
    createTheme({
      palette: {
        primary: { main: mainColor },
      },
      components: {
        MuiOutlinedInput: {
          defaultProps: {
            startAdornment: <Box sx={{ ml: '-12.5px' }} />,
          },
          styleOverrides: {
            root: {
              maxHeight: '40px',
              '& .MuiInputBase-input': {
                paddingLeft: '12px',
              },
            },
          },
        },
      },
    })
  );

  const registeredSuccessResponse = useRef({});

  const [initializedEvent, setInitializedEvent] = useState(false);

  useEffect(() => {
    if (!params.eventId) {
      return;
    }
    dispatch(setGlobalLoading(true));

    const responseQuery = new URLSearchParams(window.location.search).get('response');
    registeredSuccessResponse.current = JSON.parse(
      Buffer.from(responseQuery, 'base64').toString('utf-8')
    );

    API.getEvent(params.eventId)
      .then((response) => {
        if (response && response.data) {
          if (typeof response.data !== 'object') {
            throw new Error('Invalid Event');
          }
          API.getEventV2(params.eventId)
            .then((response) => {
              if (response && response.data) {
                if (typeof response.data !== 'object') {
                  throw new Error('Invalid Event');
                }
                if (!response.data.rsvp) {
                  setNotFound(true);
                  dispatch(setGlobalLoading(false));
                  return;
                }
                rsvpRef.current = response.data.rsvp;
                eventRef.current = response.data;
                setInitializedEvent(true);
                dispatch(setGlobalLoading(false));
              }
            })
            .catch((err) => {
              // logger.error(err);
              setNotFound(true);
              dispatch(setGlobalLoading(false));
            });
        } else {
          throw new Error('Invalid Event');
        }
      })
      .catch(() => {
        setNotFound(true);
      });
  }, [dispatch, params.eventId]);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (initializedEvent) {
      const title = getMultiLocaleContent(eventRef.current.title, i18n.language);
      if (title !== '') {
        document.title = `${title} | ${process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE}`;
      } else {
        document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
      }
    } else {
      document.title = process.env.REACT_APP_DEFAULT_DOCUMENT_TITLE;
    }
  }, [initializedEvent, i18n.language]);

  return (
    <Fragment>
      {notFound ? (
        <main>
          <div style={{ padding: '10px' }}>
            <p>Page Not Found!</p>
          </div>
        </main>
      ) : (
        <Fragment>
          {initializedEvent && (
            <ThemeProvider theme={themeRef.current}>
              <Box
                sx={{
                  maxWidth: '900px',
                  textAlign: 'start',
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                }}
              >
                {/* HEADER WITH LOGO*/}
                <Box
                  sx={{
                    alignItems: 'center',
                    background: '#FFFFFF',
                    display: 'flex',
                    height: { xs: '40px', md: '50px' },
                    justifyContent: 'space-between',
                    p: '10px',
                    borderBottom: '1px solid #dddddd',
                  }}
                >
                  <Box
                    sx={{
                      color: '#263c90',
                      fontSize: { xs: '16px', md: '32px' },
                      fontWeight: 'bold',
                      height: '100%',
                      my: '0px',
                      display: 'flex',
                      alignItems: 'center',
                      img: {
                        height: '100%',
                      },
                    }}
                  >
                    <img src={eventDuckIcon} alt="icon"></img>
                  </Box>
                  <FormLocaleSwitcher
                    supportedLocales={advance ? ['en', 'zh-Hans', 'zh-Hant'] : ['en']}
                  />
                </Box>
                <Box>
                  <ResultWrapper
                    record={{ ...eventRef.current, rsvp: rsvpRef.current }}
                    registeredSuccessResponse={registeredSuccessResponse.current}
                  />
                </Box>
              </Box>
            </ThemeProvider>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

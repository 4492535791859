import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  CircularProgress,
  createTheme,
  Input,
  Stack,
  TextField,
  ThemeProvider,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import * as API from 'api/baseAPI';
import { login } from 'redux/authSlice';
import logger from 'utils/loggerUtils';

import 'styles/scss/components/loginDialog.scss';

import otpGuide2x from 'images/otp_guide@2x.png';
import { t } from 'i18next';

const theme = createTheme({
  palette: {
    theme: {
      main: '#3FBB9B',
      contrastText: '#000000',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

const intToHex = (nr) => {
  return nr.toString(16).padStart(2, '0');
};

const generateRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
};

export default function LoginDialog(props) {
  const { open, handleClose, successHandler } = props;
  const customization = useSelector((state) => state.event.customization);
  const [customStyle, setCustomStyle] = useState({});
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [otp, setOtp] = useState({
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  });
  const [otpError, setOtpError] = useState(false);
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [otpValid, setOtpValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState('otpGuide');
  const [cognitoUser, setCognitoUser] = useState(null);
  const otpRef = useRef([]);

  const reset = () => {
    setEmail('');
    setStep('email');
    setOtp((o) => ({
      ...o,
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    }));
    setEmailError(false);
    setEmailErrorMessage('');
    setOtpError(false);
    setOtpErrorMessage('');
  };

  useEffect(() => {
    if (!open) {
      reset();
    }

    return () => {
      reset();
    };
  }, [open]);

  const handleSubmitEmail = (e) => {
    e.preventDefault();
    if (email === '') {
      return;
    }
    if (loading) {
      return;
    }
    setLoading(true);
    Auth.signIn(email)
      .then((userResponse) => {
        setCognitoUser(userResponse);
        setStep('otpGuide');
        setLoading(false);
      })
      .catch((err) => {
        if (err && err.message === 'Incorrect username or password.') {
          Auth.signUp({
            username: email,
            password: generateRandomString(30),
          })
            .then(() => {
              Auth.signIn(email).then((userResponse) => {
                setCognitoUser(userResponse);
                setStep('otpGuide');
                setLoading(false);
              });
            })
            .catch((err) => {
              logger.error(err);
              setLoading(false);
              setEmailError(true);
              setEmailErrorMessage(t('login.unknown-error'));
            });
        } else {
          logger.error(err);
          setLoading(false);
          setEmailError(true);
          if (err.message === 'Network error') {
            setEmailErrorMessage(t('login.network-error'));
          } else {
            setEmailErrorMessage(t('login.unknown-error'));
          }
        }
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
    setEmailErrorMessage('');
  };

  useEffect(() => {
    let otpCode = `${otp.code1}${otp.code2}${otp.code3}${otp.code4}${otp.code5}${otp.code6}`;
    if (otpCode.length === 6) {
      setOtpValid(true);
    } else {
      setOtpValid(false);
    }
  }, [otp.code1, otp.code2, otp.code3, otp.code4, otp.code5, otp.code6]);

  const handleOtpInput = (index) => (e) => {
    let value = e.target.value;
    let next = 0;
    if (/^\d+$/.test(value)) {
      next = index + 1;
    } else if (value === '') {
      next = index;
    } else {
      const original = otp[`code${index}`];
      value = original;
      next = index;
    }

    switch (index) {
      case 1:
        setOtp((o) => ({
          ...o,
          code1: value,
        }));
        break;
      case 2:
        setOtp((o) => ({
          ...o,
          code2: value,
        }));
        break;
      case 3:
        setOtp((o) => ({
          ...o,
          code3: value,
        }));
        break;
      case 4:
        setOtp((o) => ({
          ...o,
          code4: value,
        }));
        break;
      case 5:
        setOtp((o) => ({
          ...o,
          code5: value,
        }));
        break;
      case 6:
        setOtp((o) => ({
          ...o,
          code6: value,
        }));
        break;
      default:
        break;
    }
    setOtpError(false);

    if (next >= 1 && next <= 6) {
      otpRef.current[next].select();
    }
  };

  const handleOtpKeyDown = (index) => (e) => {
    e.target.select();
    const key = e.which || e.keyCode;
    // left key
    if (key === 37) {
      if (index > 1) {
        otpRef.current[index - 1].select();
        e.preventDefault();
      }
    }
    // right key
    if (key === 39) {
      if (index < 6) {
        otpRef.current[index + 1].select();
        e.preventDefault();
      }
    }
    // delete key and backspace key
    if (key === 46 || key === 8) {
      if (otp[`code${index}`] === '') {
        if (index > 1) {
          otpRef.current[index - 1].select();
          e.preventDefault();
          setOtp((o) => ({
            ...o,
            [`code${index - 1}`]: '',
          }));
        } else {
          setOtp((o) => ({
            ...o,
            [`code${index}`]: '',
          }));
        }
      }
    }
  };

  const handleOtpClick = (index) => (e) => {
    e.target.select();
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();

    let pasteText = (e.clipboardData || window.clipboardData).getData('text');
    if (pasteText) {
      let usedCharactersCount = 0;
      for (let i = 0; i < pasteText.length; i++) {
        if (/^\d+$/.test(pasteText[i])) {
          handleOtpInput(usedCharactersCount + 1)({ target: { value: pasteText[i] } });
          usedCharactersCount++;
        }
        if (usedCharactersCount === 6) {
          break;
        }
      }
    }
  };

  const handleSubmitOTP = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    let otpCode = `${otp.code1}${otp.code2}${otp.code3}${otp.code4}${otp.code5}${otp.code6}`;
    try {
      await Auth.sendCustomChallengeAnswer(cognitoUser, otpCode);
    } catch (err) {
      setLoading(false);
      logger.error(err);
      reset();
      setEmailError(true);
      // OPT retry over 3 times, or other reasons?
      setEmailErrorMessage(t('login.otp-fatal-error'));
      return;
    }
    try {
      await Auth.currentSession();
    } catch (err) {
      setLoading(false);
      logger.error(err);
      setOtpError(true);
      setOtpErrorMessage(t('login.otp-error'));
      return;
    }
    try {
      let response = await API.authGetMe();
      setLoading(false);
      dispatch(login(response.data.data));
      successHandler();
    } catch (err) {
      setLoading(false);
      logger.error('Invalid User');
      await Auth.signOut();
      reset();
      setEmailError(true);
      if (err && err.message === 'Network error') {
        setEmailErrorMessage(t('login.network-error'));
      } else {
        setEmailErrorMessage(t('login.unknown-error'));
      }
      return;
    }
  };

  useEffect(() => {
    if (customization.dialog) {
      if (customization.dialog.style) {
        let customStyle = {};
        for (const [key, value] of Object.entries(customization.dialog.style)) {
          customStyle[key] = value;
        }
        setCustomStyle(customStyle);
      }
    } else {
      setCustomStyle({});
    }
  }, [customization]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        className="login-dialog"
        style={customStyle}
        /* onClose={handleClose} */
        open={open}
      >
        <DialogTitle>
          {step === 'otpGuide' ? t('login.otp-guide-title') : t('login.title')}
          <button className="close-btn" onClick={handleClose}>
            <CloseIcon className="icon" />
          </button>
        </DialogTitle>
        <DialogContent>
          {step === 'email' && (
            <form onSubmit={handleSubmitEmail}>
              <TextField
                value={email}
                error={emailError}
                disabled={loading}
                onChange={handleEmailChange}
                color="theme"
                fullWidth
                label={t('login.email-label')}
                margin="dense"
                helperText={emailErrorMessage}
                sx={{
                  '.Mui-error': {
                    color: '#F62C73',
                    'fieldset.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#F62C73',
                    },
                    '.MuiInputBase-input': {
                      color: '#F62C73',
                    },
                  },
                  '.MuiFormHelperText-root': {
                    margin: '3px 0 0 0',
                    backgroundColor: 'initial',
                  },
                  '.MuiInputBase-input': {
                    backgroundColor: '#FFFFFF',
                    color: '#3FBB9B',
                    '&:placeholder': {
                      color: '#333333',
                    },
                    '&.Mui-disabled': {
                      color: '#333333',
                      WebkitTextFillColor: 'currentcolor',
                    },
                  },
                  '.MuiInputLabel-root': {
                    color: '#999999',
                    '&.Mui-disabled': {
                      color: '#333333',
                    },
                  },
                  '.MuiOutlinedInput-root': {
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#333333',
                    },
                    '&:hover': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#3FBB9B',
                      },
                    },
                    '&.Mui-disabled': {
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#333333',
                      },
                    },
                  },
                }}
                inputProps={{
                  pattern:
                    "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`\\{\\|\\}~\\-]+@[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9\\-]{0,61}[a-zA-Z0-9])?)+$", //eslint-disable-line
                  required: true,
                }}
              />
              <Box sx={{ color: '#666666', my: '20px', textAlign: 'left' }}>
                {t('login.not-registered-remark')}
              </Box>
              <Button
                color="theme"
                variant="contained"
                fullWidth
                disabled={loading || email === ''}
                type="submit"
                sx={{
                  height: '40px',
                  maxWidth: '200px',
                  color: '#FFFFFF',
                  '&.Mui-disabled': {
                    color: '#999999',
                    backgroundColor: '#ECECEC',
                  },
                }}
              >
                {loading ? <CircularProgress size={25} /> : t('app.confirm')}
              </Button>
            </form>
          )}
          {step === 'otpGuide' && (
            <Box>
              <Box sx={{ color: '#666666', my: '20px' }}>{t('login.otp-remark-1')}</Box>
              <Box sx={{ color: '#3FBB9B', my: '20px' }}>{email}</Box>
              <Box sx={{ color: '#666666', my: '20px' }}>{t('login.otp-remark-2')}</Box>
              <Box sx={{ my: '20px', img: { width: '100%', maxWidth: '400px' } }}>
                <img src={otpGuide2x} alt="opt-guide" />
              </Box>
              <Button
                onClick={() => {
                  setStep('otp');
                }}
                color="theme"
                variant="contained"
                fullWidth
                sx={{
                  height: '40px',
                  maxWidth: '200px',
                  my: '20px',
                  color: '#FFFFFF',
                  '&.Mui-disabled': {
                    color: '#999999',
                    backgroundColor: '#ECECEC',
                  },
                }}
              >
                {' '}
                {t('login.next-step')}{' '}
              </Button>
              <Box sx={{ color: '#666666', my: '30px' }}>{t('app.footer')}</Box>
            </Box>
          )}
          {step === 'otp' && (
            <form onSubmit={handleSubmitOTP}>
              <Box sx={{ color: '#666666', my: '20px' }}>{t('login.otp-label')}</Box>
              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2 }}
                justifyContent="center"
                sx={{
                  '.MuiInputBase-root': {
                    input: {
                      border: '1px solid #333333',
                      borderRadius: '4px',
                      color: '#3FBB9B',
                      fontSize: '24px',
                      height: {
                        xs: '36px',
                        sm: '50px',
                      },
                      width: {
                        xs: '36px',
                        sm: '50px',
                      },
                      textAlign: 'center',
                      padding: '0px',
                    },
                    '&.Mui-error': {
                      input: {
                        borderColor: '#F62C73',
                        backgroundColor: 'rgba(246, 44, 115, 0.1)',
                      },
                    },
                    '&.Mui-focused': {
                      input: {
                        borderColor: '#3FBB9B',
                      },
                    },
                    '&.Mui-disabled': {
                      input: {
                        color: '#333333',
                        WebkitTextFillColor: 'currentcolor',
                      },
                    },
                  },
                }}
              >
                <Input
                  inputRef={(el) => (otpRef.current[1] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code1}
                  onChange={handleOtpInput(1)}
                  onKeyDown={handleOtpKeyDown(1)}
                  onClick={handleOtpClick(1)}
                  onPaste={handleOtpPaste}
                />
                <Input
                  inputRef={(el) => (otpRef.current[2] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code2}
                  onChange={handleOtpInput(2)}
                  onKeyDown={handleOtpKeyDown(2)}
                  onClick={handleOtpClick(2)}
                  onPaste={handleOtpPaste}
                />
                <Input
                  inputRef={(el) => (otpRef.current[3] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code3}
                  onChange={handleOtpInput(3)}
                  onKeyDown={handleOtpKeyDown(3)}
                  onClick={handleOtpClick(3)}
                  onPaste={handleOtpPaste}
                />
                <Input
                  inputRef={(el) => (otpRef.current[4] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code4}
                  onChange={handleOtpInput(4)}
                  onKeyDown={handleOtpKeyDown(4)}
                  onClick={handleOtpClick(4)}
                  onPaste={handleOtpPaste}
                />
                <Input
                  inputRef={(el) => (otpRef.current[5] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code5}
                  onChange={handleOtpInput(5)}
                  onKeyDown={handleOtpKeyDown(5)}
                  onClick={handleOtpClick(5)}
                  onPaste={handleOtpPaste}
                />
                <Input
                  inputRef={(el) => (otpRef.current[6] = el)}
                  disableUnderline={true}
                  disabled={loading}
                  error={otpError}
                  inputProps={{
                    required: true,
                    autoComplete: 'off',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    maxLength: 1,
                    pattern: '[0-9]*',
                    type: 'tel',
                  }}
                  value={otp.code6}
                  onChange={handleOtpInput(6)}
                  onKeyDown={handleOtpKeyDown(6)}
                  onClick={handleOtpClick(6)}
                  onPaste={handleOtpPaste}
                />
              </Stack>
              {otpError && (
                <Box sx={{ color: '#F62C73', fontSize: '0.75rem' }}>{otpErrorMessage}</Box>
              )}
              <Button
                color="theme"
                variant="contained"
                fullWidth
                disabled={loading || !otpValid}
                type="submit"
                sx={{
                  height: '40px',
                  maxWidth: '200px',
                  my: '20px',
                  color: '#FFFFFF',
                  '&.Mui-disabled': {
                    color: '#999999',
                    backgroundColor: '#ECECEC',
                  },
                }}
              >
                {loading ? <CircularProgress size={25} /> : t('app.confirm')}
              </Button>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

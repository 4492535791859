const translation = {
  general: {
    minute_one: '{{ count }}分钟',
    minute_other: '{{ count }}分钟',
    hour_one: '{{ count }}小时',
    hour_other: '{{ count }}小时',
  },
  app: {
    exit: '退出',
    close: '关闭',
    footer: '由 AsiaSat Media Technology Limited 提供技术支持',
    'login-btn': '登录',
    'logout-btn': '登出',
    confirm: '确认',
  },
  login: {
    title: '用户登录',
    'email-label': '输入您的电邮',
    'not-registered-remark': '如果您尚未注册，请输入您的电邮地址并点击下方"确认"按钮。',
    'otp-guide-title': '验证码已发送',
    'otp-label': '输入您的验证码',
    'otp-remark-1': '我们已将验证码发送到您的电邮地址。',
    'otp-remark-2': '请在15分钟内完成登录。',
    'next-step': '下一步',
    'email-error': '电邮不正确，请重新登录。',
    'unknown-error': '发生错误，请重新登录。',
    'network-error': '检测到网络连接问题。如果问题持续，请尝试使用其他网络。',
    'otp-error': '验证码不正确，请重新输入。',
    'otp-fatal-error': '验证码超时或错误次数超过3次，请重新登录。',
  },
  home: {
    title: '首页',
  },
  event: {
    info: '详情',
    'q-and-a': '问答',
    question: {
      placeholder: '设有问答环节，如您有任何提问，请在这里输入。',
      submit: '提交',
      'succeed-message': '感谢您的提问。',
      'failed-message': '提交失败，请稍后再试。',
      'exceeds-char-limit-message': '最多可以输入100个中文字（200字符）。',
    },
    chatroom: '聊天室',
    polling: '投票',
    schedule: '时间表',
    si: {
      english: '英语',
      cantonese: '粤语',
      putonghua: '普通话',
    },
    playback: {
      'error-title': '视频播放错误',
      'error-message': '您的网络可能不稳定，或者您没有权限观看此视频。',
    },
    'just-started': '刚刚开始',
    'started-ago': '{{age}}前开始',
    'concurrent-error-message': '您的账户已在其他设备上登录。',
    'connection-error-message': '网络不稳定。',
    'require-login-message': '如果您有权限观看此活动，请登录。',
    'session-kicked-error-message': '您的账户已在其他设备上登录。点击下方按钮在此设备上观看。',
    'view-here': '在此观看',
    unauthorized: '您无权查看此内容。',
  },
  passcode: {
    label: '请输入您的密码：',
    enter: '确定',
    remark: '如果您未取得密码，请联系活动主办方。',
    error: '密码不正确。',
    'select-placeholder': '请选择',
  },
  register: {
    'date-and-time': '日期和时间',
    venue: '地点',
    about: '关于此活动',
    'step-1-label': '活动信息',
    'step-2-label': '填写 RSVP 表格',
    'step-3-label': '确认',
    'step-4-label': '完成',
    'register-finish-title': '注册成功！',
    'register-finish-desc-1': '签到时请出示以下二维码。',
    'register-finish-desc-2': '您可以截图或下载图片。',
    'register-finish-virtual-desc-1': '请检查您的电子邮件以获取虚拟活动链接。期待您的参与！',
    'register-finish-general-desc-1': '请检查您的电子邮件以获取活动详情。',
    'register-finish-general-desc-2': '我们期待您的参与！',
    'confirm-your-registration': '确认您的注册',
    payment: '付款',
    'amount-to-pay': '应付金额',
    original: '原价',
    discounted: '优惠价',
    'label-download-image': '下载图片',
    'label-join-now': '立即注册',
    'label-back': '返回',
    'label-submit': '提交',
    'label-confirm': '确认',
    'label-region': '地区',
    'label-required-field': '* 表示必填栏位',
    'label-register-form': '注册表格',
    'label-discount-code': '优惠码',
    'error-fail-download-image': '无法下载图片。',
    'error-email-registered': '此电子邮件地址已经注册。',
    'error-fail-register': '提交表单失败。请稍后再试。',
    'error-fail-email-not-found': '提交表单失败。找不到电子邮件栏位。',
    'error-invalid-field': '{{field}} 不正确。',
    'error-invalid-discount-code': '优惠码不正确。',
    'error-fail-to-apply-discount-code': '应用优惠码失败。',
    'error-discount-code-quota-exceeded': '优惠码配额已用完。',
    'error-fail-to-confirm': '确认失败。',
    apply: '应用',
    pay: '付款',
    price: '价格',
    'error-timeout': '您的会话已过期，请确认您的信息并重新提交。',
    'time-left': '剩余时间：{{time}}',
    'reference-no': '参考编号',
    'payment-failed': '付款失败，请重试。',
    'payment-processing': '付款处理中，请稍候...',
  },
};

export default translation;

import React, { useState, Fragment } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getMultiLocaleContent, getTimeRange } from 'utils/helper';

const ReactHookCheckBox = (props) => {
  const { field, ...rest } = props;
  const { register, watch } = useFormContext();
  const checked = watch(field.key);
  return (
    <Checkbox
      {...rest}
      {...register(field.key)}
      name={field.key}
      required={field?.required}
      checked={checked}
    />
  );
};

const ConfirmButton = (props) => {
  const { onConfirmClick, ...rest } = props;
  const { t } = useTranslation();
  const formData = useWatch({});
  return (
    <Button
      {...rest}
      variant="contained"
      sx={{
        ml: 'auto',
        '& .MuiButton-startIcon': { marginRight: '0px' },
        width: ['100%', 'auto'],
        my: [2, 0],
        textTransform: 'none',
      }}
      onClick={() => {
        onConfirmClick(formData);
      }}
    >
      <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-confirm')}</Box>
    </Button>
  );
};

const RsvpDropDownInput = (props) => {
  const { field, label, formRegKey, ...rest } = props;
  const { register, getValues } = useFormContext();
  const { i18n } = useTranslation();
  const [value, setValue] = useState(
    getValues(formRegKey) ?? field?.options?.find((it) => it.key === field?.default?.[0])?.key ?? ''
  );

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const displayLabel =
    label ??
    `${getMultiLocaleContent(field?.label, i18n.language, true) ?? formRegKey}${
      field?.required ? '*' : ''
    }`;

  return (
    <Fragment>
      <Box
        component="label"
        id={`select-${formRegKey}-label`}
        htmlFor={`select-${formRegKey}`}
        sx={{ fontSize: '12px', color: 'text.secondary' }}
      >
        {displayLabel}
      </Box>
      <FormControl {...rest}>
        <Select
          {...register(formRegKey)}
          native
          hiddenLabel
          name={formRegKey}
          size="small"
          id={`select-${formRegKey}`}
          value={value}
          onChange={handleChange}
          inputProps={{
            required: field?.required,
          }}
        >
          {field?.options?.map((it) => {
            return (
              <option key={it.key} value={it.key}>
                {getMultiLocaleContent(it, i18n.language, true)}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

const RsvpFormPreview = (props) => {
  const { record, onBackClick, onSubmitClick, isConfirmMode, ...rest } = props;
  const { register, watch } = useFormContext();
  const { i18n, t } = useTranslation();

  // console.log('defaultData', defaultData);
  // console.log('record', record);
  // const domain = 'https://dev-event.ocgl.live';
  const domain = process.env.REACT_APP_ASSET_BASE_URL;
  const rsvpData = record?.rsvp;
  const bannerUrl = rsvpData?.banner ? domain + rsvpData?.banner : null;

  const addressText = getMultiLocaleContent(rsvpData?.address?.text, i18n.language, true);
  const addressLat = rsvpData?.address?.lat;
  const addressLon = rsvpData?.address?.lon;
  const addressZoom = parseFloat(rsvpData?.address?.z ?? 10).toFixed(0);

  return (
    <Box
      {...rest}
      sx={{
        m: '20px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* <Typography variant="h5" sx={{ mb: 2 }}>
        Preview
      </Typography> */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          border: 1,
          borderRadius: '10px',
          borderColor: 'divider',
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${bannerUrl})`,
            backgroundPosition: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            pt: `${(9 / 16) * 100}%`,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
          }}
        >
          <Typography sx={{ fontWeight: 500, typography: { xs: 'h4', md: 'h3' } }}>
            {getMultiLocaleContent(record?.title, i18n.language, true)}
          </Typography>

          <Typography variant="h5" sx={{ fontWeight: 500, mt: 4 }}>
            {t('register.date-and-time')}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: 500,
              display: 'flex',
              alignItems: 'flex-start',
              my: 1,
              color: '#333333',
            }}
          >
            <CalendarMonthIcon sx={{ fontSize: '20px', mr: 1 }} />
            {getTimeRange(record, i18n.language)}
          </Typography>

          {addressText && (
            <Fragment>
              <Typography variant="h5" sx={{ fontWeight: 500, mt: 4 }}>
                {t('register.venue')}
              </Typography>
              {addressLat && addressLon && (
                <Box
                  id="previewInput_address_embedUrl"
                  sx={{
                    mt: 2,
                    width: ['100%', '500px'],
                    height: '300px',
                    iframe: {
                      border: 0,
                      width: '100%',
                      height: '100%',
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://maps.google.com/maps?q=${addressLat},${addressLon}&hl=${i18n.language}&z=${addressZoom}&output=embed"/>`,
                  }}
                />
              )}
              <Box
                id="previewInput_address"
                sx={{ display: 'inline-flex', alignItems: 'top', my: 1, color: '#333333' }}
              >
                <PlaceIcon sx={{ fontSize: '20px', mr: 1 }} />
                <Typography
                  variant="subtitle2"
                  sx={{ m: 0, whiteSpace: 'pre-line', wordBreak: 'break-word' }}
                >
                  {addressText}
                </Typography>
              </Box>
            </Fragment>
          )}

          {!isConfirmMode ? (
            <Typography
              variant="subtitle1"
              dangerouslySetInnerHTML={{
                __html: getMultiLocaleContent(rsvpData?.description, i18n.language, true),
              }}
            />
          ) : (
            <Typography variant="subtitle1">
              <p>Please review your inputs</p>
            </Typography>
          )}

          {/* Dynamic Fields */}
          {/* Dynamic Fields */}
          {!isConfirmMode ? (
            <Fragment>
              {rsvpData?.fields?.map((field, index) => {
                if (field.type === 'select') {
                  return (
                    <RsvpDropDownInput
                      disabled={isConfirmMode}
                      formRegKey={field.key}
                      id={`previewInput_${index + 1}`}
                      key={`${index}-${field.id}`}
                      fullWidth
                      field={field}
                      size="small"
                      sx={{ mt: 0, mb: 2 }}
                    />
                  );
                } else if (field.type === 'tel') {
                  return (
                    <Box
                      id={`previewInput_${index + 1}`}
                      key={`${index}-${field.id}`}
                      sx={{
                        display: 'flex',
                        flexDirection: ['column', 'row'],
                        alignItems: 'center',
                      }}
                    >
                      <Box sx={{ width: ['100%', '50%'] }}>
                        <RsvpDropDownInput
                          disabled={isConfirmMode}
                          field={field}
                          fullWidth
                          label={t('register.label-region')}
                          formRegKey={`${field.key}Region`}
                          sx={{ mt: 0, mb: 2 }}
                        />
                      </Box>
                      <Box sx={{ width: ['100%', '50%'], ml: [0, 2] }}>
                        <Box
                          component="label"
                          id={`previewInput_${index + 1}_number-label`}
                          htmlFor={`previewInput_${index + 1}_number`}
                          sx={{ fontSize: '12px', color: 'text.secondary' }}
                        >{`${
                          getMultiLocaleContent(field?.label, i18n.language, true) ?? field.key
                        }${field.required ? '*' : ''}`}</Box>
                        <TextField
                          disabled={isConfirmMode}
                          {...register(field.key)}
                          size="small"
                          fullWidth
                          type={field.type}
                          id={`previewInput_${index + 1}_number`}
                          sx={{
                            mt: 0,
                            mb: 2,
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                          }}
                          hiddenLabel
                          margin="dense"
                          inputProps={{
                            required: field.required,
                          }}
                        />
                      </Box>
                    </Box>
                  );
                } else if (field.type === 'checkbox') {
                  return (
                    <FormControlLabel
                      key={`${index}-${field.id}`}
                      sx={{ alignItems: 'flex-start' }}
                      control={
                        <ReactHookCheckBox field={field} disabled={isConfirmMode} sx={{ mt: 1 }} />
                      }
                      label={
                        <Box sx={{ display: 'inline-flex' }}>
                          <Box sx={{ mt: 2 }}>{field.required ? '*' : ''}</Box>
                          <Typography
                            id={`previewInput_${index + 1}`}
                            variant="subtitle1"
                            dangerouslySetInnerHTML={{
                              __html: getMultiLocaleContent(
                                field?.description,
                                i18n.language,
                                true
                              ),
                            }}
                          />
                        </Box>
                      }
                    />
                  );
                } else if (field.type === 'hidden') {
                  return (
                    <TextField
                      disabled={isConfirmMode}
                      {...register(field.key)}
                      name={field.key}
                      id={`previewInput_${index + 1}`}
                      key={`${index}-${field.id}`}
                      type={field.type}
                      size="small"
                      fullWidth
                      margin="dense"
                      variant="filled"
                      sx={{ display: 'none' }}
                      value={field.default}
                    />
                  );
                } else {
                  return (
                    <Fragment>
                      <Box
                        component="label"
                        id={`previewInput_${index + 1}-label`}
                        htmlFor={`previewInput_${index + 1}`}
                        sx={{ fontSize: '12px', color: 'text.secondary' }}
                      >{`${getMultiLocaleContent(field?.label, i18n.language, true) ?? field.key}${
                        field.required ? '*' : ''
                      }`}</Box>
                      <TextField
                        disabled={isConfirmMode}
                        {...register(field.key)}
                        name={field.key}
                        id={`previewInput_${index + 1}`}
                        key={`${index}-${field.id}`}
                        type={field.type}
                        size="small"
                        sx={{ mt: 0, mb: 2 }}
                        fullWidth
                        hiddenLabel
                        margin="dense"
                        inputProps={{
                          required: field.required,
                          pattern: field.pattern,
                        }}
                      />
                    </Fragment>
                  );
                }
              })}
            </Fragment>
          ) : (
            <Fragment>
              {rsvpData?.fields?.map((field, index) => {
                const value = watch(field.key);
                if (field.type === 'hidden') {
                  return null;
                }
                return (
                  <Box key={`${index}-${field.id}`} sx={{ my: 1 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      {getMultiLocaleContent(field?.label, i18n.language, true) ?? field.key}
                    </Typography>
                    {field.type === 'tel' ? (
                      <Typography>
                        {watch(`${field.key}Region`)} {value}
                      </Typography>
                    ) : field.type === 'checkbox' ? (
                      <Typography>{value ? 'Yes' : 'No'}</Typography>
                    ) : field.type === 'select' ? (
                      <Typography>
                        {getMultiLocaleContent(
                          field.options?.find((opt) => opt.key === value),
                          i18n.language,
                          true
                        )}
                      </Typography>
                    ) : (
                      <Typography>{value}</Typography>
                    )}
                  </Box>
                );
              })}
            </Fragment>
          )}

          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: ['column', 'row'],
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Button
              variant="outlined"
              sx={{
                '& .MuiButton-startIcon': { marginRight: '0px' },
                width: ['100%', 'auto'],
                textTransform: 'none',
              }}
              onClick={() => {
                onBackClick();
              }}
            >
              <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-back')}</Box>
            </Button>

            {!isConfirmMode && (
              <Button
                variant="contained"
                sx={{
                  '& .MuiButton-startIcon': { marginRight: '0px' },
                  width: ['100%', 'auto'],
                  my: [2, 0],
                  textTransform: 'none',
                }}
                onClick={() => {
                  onSubmitClick();
                }}
              >
                <Box sx={{ m: 'auto', py: 1, px: 3 }}>{t('register.label-submit')}</Box>
              </Button>
            )}
            {isConfirmMode && <ConfirmButton onConfirmClick={onSubmitClick} />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default RsvpFormPreview;
